export const categories = [
  { code: "HOME", title: "Home", icon: "fas fa-home" },
  { code: "FAQ", title: "FAQs", icon: "fas fa-question-circle" },
]

export const resources = [
  {
    code: "HOME",
    resourcesList: [
      {
        title: "Spotlight",
        description: "Trending",
        source: [
          {
            sourceTitle: "Previous Question Papers",
            sourceURL:
              "https://play.google.com/store/apps/details?id=com.vitspot.app",
          },
          {
            sourceTitle: "FFCS (Fully Flexible Credit System) Explained",
            sourceURL: "https://vitspot.com/posts/vit-ffcs/",
          },
          {
            sourceTitle: "Best places to visit near VIT",
            sourceURL: "https://vitspot.com/posts/places-around-vit/",
          },
          {
            sourceTitle: "The Ultimate Hostel Checklist",
            sourceURL: "https://vitspot.com/posts/vit-hostel-checklist/",
          },
          {
            sourceTitle: "VIT Club List",
            sourceURL: "https://vitspot.com/clubs/",
          },
          {
            sourceTitle: "Developer Roadmaps",
            sourceURL: "https://roadmap.sh/",
          },
        ],
      },
      {
        title: "Social",
        description: "Get lightning fast updates on campus",
        source: [
          {
            sourceTitle: "VITspot",
            sourceURL: "https://www.instagram.com/vitspot/",
          },
          {
            sourceTitle: "VITspot Plus",
            sourceURL: "https://www.instagram.com/vitspotplus/",
          },
          {
            sourceTitle: "VITspot Confessions",
            sourceURL: "https://www.instagram.com/vitspotconfessions/",
          },
          {
            sourceTitle: "VITspot Telugu",
            sourceURL: "https://www.instagram.com/vitspottelugu/",
          },
        ],
      },
    ],
  },
  {
    code: "FAQS",
  },
];

import React from 'react'

export const Resourcelinks = ({source}) => {
    return (
        <div className="mt-2 content flex is-flex-direction-column">
            {source.map(({sourceTitle,sourceURL})=>{
                return <div className="mb-4"><a href={sourceURL} target="_blank">{sourceTitle}</a><br/></div>
            })}
        </div>
    )
}

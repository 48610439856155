/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import JuniorLogo from "../../img/junior.png";
import { resources, categories } from "../../utils/juniorresources";
import { Resourcecard } from "./card";

const Junior = () => {
  const [currentCategory, setCurrentCategory] = useState("HOME");
  const [currentResources, setCurrentResources] = useState(resources[0]);

  const filterCategory = (resources, code) => {
    const filteredResources = resources.filter(
      resource => resource.code === code
    );

    return filteredResources[0];
  };

  return (
    <div>
      <img src={JuniorLogo} alt="Social" width="200" height="200" />
      <div className="tabs is-left">
        <ul>
          {categories.map(({ code, title, icon }) => (
            <li
              className={currentCategory === code ? "is-active" : ""}
              key={code}
            >
              <a
                onClick={() => {
                  setCurrentCategory(code);
                  setCurrentResources(filterCategory(resources, code));
                }}
              >
                <span className="icon is-small">
                  <i className={icon} aria-hidden="true" />
                </span>
                <span>{title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {currentCategory === "HOME" && (
        <div>
          <div className="mb-5 mt-3">
            <a
              href="https://drive.google.com/drive/folders/1K-jlYKy32wF0h42xZUfJoCgO_3QSNv00?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              <div className="notification is-black">
                <p className="is-size-5 has-text-centered">
                  Freshers Resources
                </p>
              </div>
            </a>
          </div>
          <div className="mb-5">
            <a href="https://vitspot.com/class-groups" target="_blank">
              <div className="notification is-black">
                <p className="is-size-5 has-text-centered">
                  VIT Class WhatsApp Group Links
                </p>
              </div>
            </a>
          </div>
        </div>
      )}
      {currentCategory === "FAQ" && (
        <div className="notification is-info is-light is-size-4 has-text-centered">
          <h2>
            <strong>Coming soon</strong>
          </h2>
        </div>
      )}
      {currentResources &&
        currentResources.resourcesList.map(({ title, description, source }) => (
          <Resourcecard
            source={source}
            title={title}
            description={description}
          />
        ))}
    </div>
  );
};
export default Junior;

import React from "react"
import { Resourcelinks } from "./links"

export const Resourcecard = ({title,description,source}) => {
  return (
    <div>
      <div className="card mb-4">
        <div className="card-content">
          <div className="content">
              <h3 className="is-size-3">{title}</h3>
              {/* <h6 className="is-size-6 has-text-grey">{description}</h6> */}
          <Resourcelinks source={source} />
          </div>
        </div>
      </div>
    </div>
  )
}
